@import './variables';

.Wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.Button {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  outline: none;
  border: none;
  color: $primary;
  background-color: transparent;
  font-size: 130%;
}

.Button:hover, .Button:active {
  color: #a3007a;
}

.Add {
  margin-right: 1rem;
}