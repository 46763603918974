$white: #fff;
$black: #000;
$primary: #c10097;
$body-bg: $white;
$body-color: $black;
$lightgray: lightgray;

$input-bg: $white;

// Button
$btn-border-radius: 2rem;

@import './node_modules/bootstrap/scss/functions';
@import './node_modules/bootstrap/scss/variables';