@import './variables';

.Container {
  display: flex;
  flex: 1;    
  flex-direction: column;  
}

.OnlineContainer {
  background-color: $primary;
  color: $white;
  display: flex;
  padding: 0.3rem 0.4rem;
  align-items: center;
}

.Copy {
  margin-left: 1rem;
}

.CopyButton {
  display: flex;
  align-items: center;
  outline: none;
  border: none;
  background-color: transparent;
  color: $white;
}

.CopyButton:hover, .CopyButton:active {
  opacity: 0.5;
}

.onlineButton {
  flex: 1;
  margin-bottom: 1rem;
}