@import './variables';

.Wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.Button {
  display: flex;
  align-items: center;
  outline: none;
  border: none;
  color: $primary;
  background-color: transparent;
}

.Button:hover, .Button:active {
  color: #a3007a;
}
