@import './variables';
@import './node_modules/bootstrap/scss/bootstrap';

html, body, #root {
  height: 100%;
}

#root {
  display: flex;
  flex-direction: column;
}
