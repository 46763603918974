@import './variables';

.Container {
  display: flex;
  flex-direction: column;
  margin: auto;
}

.Item {
  color: $primary;
}

.Wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.Button {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  outline: none;
  border: none;
  color: $primary;
  background-color: transparent;
  font-size: 130%;
}

.Button:hover, .Button:active {
  color: #a3007a;
}

.Arrow {
  margin-left: 1rem;
}