@import './variables';

.Input {
  display: flex; 
  flex: 1;
  border-radius: 30px;
  padding: 0.5rem 1rem;  
  text-align: center;  
  border: 1px solid $black;  
  color: $black;
  outline: none;
  font-size: medium;
}

.Selected { 
  background-color: $primary;
  color: $white;
  border-color: $white;
  &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: $white;
    opacity: 1; /* Firefox */
  }
  &:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: $white;
  }
  &::-ms-input-placeholder { /* Microsoft Edge */
    color: $white;
  }
}
