@import './variables';

.footer {
  background-color: $white;
  color: $black;
  font-size: 120%;
  margin-top: auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  button {
    background-color: unset;
    border: unset;
    padding: unset;
    text-decoration: underline;
  }
}
