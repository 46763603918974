@import './variables';

.Container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
}

.ChooseFlag {
  border: none;
  display: flex;  
  position: relative;
  font-size: larger;
}

.ChooseFlagSelected {
  display: flex;
  flex: 1;
  justify-content: space-between;
  color: $white;
}

.ChooseFlagOptions {
  position: absolute;
  background-color: $white;
  width: inherit;
  border-radius: 0.5rem;
  z-index: 1;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.3);
  margin-top: 0.5rem;
  display: block;
  padding: 0.5rem 0;
  transform: translateY(1.5rem);
}

.ChooseFlagOption {
  padding: 0.5rem 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.ChooseFlagOption:hover {
  background-color: $lightgray;
}
